// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-inspector-tabular-data-styles__highlightedRow {
  background-color: #d7d4f0;
}
`, "",{"version":3,"sources":["webpack://./src/inspector/tabular-data.styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B","sourcesContent":[":local(.highlightedRow) {\n  background-color: #d7d4f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highlightedRow": `src-inspector-tabular-data-styles__highlightedRow`
};
export default ___CSS_LOADER_EXPORT___;
