import React from "react";
import { get, partial } from "lodash";
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed";
import { CpEmptyState } from "canopy-styleguide!sofe";

import searchStyles from "./task-selector.styles.css";

//-----------------------------------------------------------------------------

export const BEGIN_TASK_SEARCH = "begin-task-search";

export const isHovered = (index, hoverIndex) =>
  index === hoverIndex ? "#F7F7F7" : "#FFF";

export const getRecentTasks = (loggedInUserId) => {
  return JSON.parse(
    localStorage.getItem(`${loggedInUserId}-recentTaxPrepTasks`) || "[]"
  );
};

export const setRecentTasks = (loggedInUserId, task) => {
  const maxRecentTasks = 10;
  const localStorageKey = `${loggedInUserId}-recentTaxPrepTasks`;
  const localStorageValue = JSON.parse(
    localStorage.getItem(localStorageKey) || "[]"
  );
  const filteredTasks = localStorageValue.filter((c) => c.id !== task.id);
  localStorage.setItem(
    localStorageKey,
    JSON.stringify(
      [
        {
          client_id: task.client_id,
          client_name: task.client_name,
          resolution_case_id: task.resolution_case_id,
          id: task.id,
          name: task.name,
        },
        ...filteredTasks,
      ].filter((c, idx) => idx < maxRecentTasks)
    )
  );
};

export const RecentTasksList = ({
  hoverIndex,
  setHoveredIndex,
  setSelectedTaskCallback,
  recentTasks,
}) => {
  recentTasks =
    recentTasks ||
    getRecentTasks(
      get(window, "loggedInUser.id", get(opener, "loggedInUser.id", 1))
    );
  return (
    <div className={searchStyles.taskList}>
      <li
        style={{
          background: "#fff",
          height: "35px",
          position: "sticky",
          top: "0px",
        }}
      >
        <div className={searchStyles.resultHeader}>
          <div className="cps-margin-left-4 cps-medium-gray">
            Recently viewed tax prep tasks
          </div>
          <div
            className="cps-margin-left-8"
            style={{ flexGrow: 2, borderTop: "1px solid #e5e5e5" }}
          />
        </div>
      </li>
      {recentTasks.length ? (
        recentTasks.map((recentTask, index) => (
          <TaskListEntry
            displayValue={`${recentTask.name}${
              recentTask.client_name ? `; ${recentTask.client_name}` : ""
            }${recentTask.id ? `; ${recentTask.id}` : ""}`}
            hoverIndex={hoverIndex}
            key={index}
            searchString=""
            setHoveredIndex={setHoveredIndex}
            setSelectedTaskCallback={setSelectedTaskCallback}
            task={{ ...recentTask, Index: index }}
          />
        ))
      ) : (
        <i className="cps-padding-left-16">None</i>
      )}
    </div>
  );
};

export const SearchResults = ({
  tasks,
  hoverIndex,
  searchString,
  setHoveredIndex,
  setSelectedTaskCallback,
  isKeyboard,
  handleScroll,
}) => {
  return (
    <div
      className={searchStyles.taskList}
      onWheel={handleScroll}
      style={{ maxHeight: "300px" }}
    >
      {tasks.length ? (
        <>
          <li
            style={{
              background: "#fff",
              height: "35px",
              position: "sticky",
              top: "0px",
            }}
          >
            <div className={searchStyles.resultHeader}>
              <div className="cps-margin-left-4 cps-medium-gray">
                Search results
              </div>
              <div
                className="cps-margin-left-8"
                style={{ flexGrow: 2, borderTop: "1px solid #e5e5e5" }}
              />
            </div>
          </li>
          {tasks.map((task, index) => {
            return (
              <div
                key={task.id}
                ref={(e) => {
                  index == hoverIndex &&
                    e &&
                    isKeyboard &&
                    scrollIntoViewIfNeeded(
                      e,
                      false,
                      { centerIfNeeded: false },
                      document.querySelector(`#papaDiv`)
                    );
                }}
              >
                <TaskListEntry
                  displayValue={`${task.name}; ${task.client_name}; ${task.id}`}
                  hoverIndex={hoverIndex}
                  navigationWithKeyboard={isKeyboard}
                  searchString={searchString}
                  setHoveredIndex={setHoveredIndex}
                  setSelectedTaskCallback={setSelectedTaskCallback}
                  task={{ ...task, Index: index }}
                />
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export const TaskListEntry = ({
  task,
  displayValue,
  hoverIndex,
  searchString,
  setHoveredIndex,
  setSelectedTaskCallback,
  navigationWithKeyboard,
}) => {
  return (
    <li
      onMouseEnter={() => {
        if (!navigationWithKeyboard) {
          setHoveredIndex(task.Index);
        }
      }}
      onMouseDown={partial(setSelectedTaskCallback, task)}
    >
      <div
        key={task.id}
        style={{
          alignItems: "center",
          background: isHovered(task.Index, hoverIndex),
          display: "flex",
          justifyContent: "space-between",
          padding: "4px 12px",
        }}
      >
        <div className="cps-ellipsis">
          <a
            className="cps-body-sm cps-gray-10 cps-margin-0"
            title={displayValue}
          >
            {searchString
              ? styleMatchingText(
                  displayValue,
                  searchString.toLowerCase().trim()
                )
              : displayValue}
          </a>
        </div>
      </div>
    </li>
  );
};

export const NoSearchResults = () => {
  return (
    <CpEmptyState
      img="es_tasks_list"
      text="No tax prep tasks found"
      subText="Please refine your search criteria"
    />
  );
};

export function styleMatchingText(thingToSearch = "", searchTerm) {
  if (!searchTerm) return thingToSearch;

  const stringToSearch = thingToSearch.toString();

  const stop = stringToSearch.toLowerCase().indexOf(searchTerm.toLowerCase());

  if (stop === -1) return stringToSearch;

  const bold = stringToSearch.slice(stop, stop + searchTerm.length);
  const nonBold = stringToSearch.slice(0, stop);
  const rest = stringToSearch.slice(
    stop + searchTerm.length,
    stringToSearch.length
  );

  return (
    <span>
      {nonBold}
      <strong className="cps-primary-green">{bold}</strong>
      {rest}
    </span>
  );
}
