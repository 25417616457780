const argMap = {
  aggregate: 2,
  aggregateif: 3,
  and: 0,
  collect: 2,
  combine: 2,
  if: 0,
  max: 3,
  min: 3,
  or: 0,
  sum: 2,
  var: 2,
  evalvar: 0,
};

const allowedFunc =
  "abs|age|aggregate|aggregateif|amount|and|ceiling|choose|collect|combine|contains|counta|countif|country|date|datedif|day|ein|evalvar|filter|filterbylist|floor|groupby|if|ifonly|index|innergroup|large|left|length|lookup|lower|matches|max|mid|min|month|not|now|number|or|padlist|percent|permission|phone|pillbox|replace|right|round|size|sort|spaceby|ssn|state|summarygroup|subset|sum|sumby|sumif|tlookup|topgroup|unique|var|upper|year|zip";

function isFunc(str) {
  const funcList = new RegExp(`(${allowedFunc})$`, "i");
  const result = funcList.exec(str);

  if (result) {
    return {
      index: result.index,
      name: result[0].toLowerCase(),
    };
  }
  return false;
}

function countArgs(src) {
  let args = 1;
  let blocks = 0;
  let inComment = false;
  let found = false;
  for (let i = 0; i < src.length; i++) {
    if (!found) {
      const char = src[i];
      const isNewLine = !!/\r|\n/.test(char);
      if (char === "#") inComment = true;
      if (isNewLine) inComment = false;
      if (!inComment) {
        if (char === "(") {
          blocks++;
        }
        if (char === ")") {
          blocks--;
        }
        if (blocks === 1 && char === ",") args++;
      }
      if (blocks === 0) {
        found = true;
      }
    }
  }
  return args;
}

function buildIndent(blocks) {
  const indent = blocks.reduce((acc, next) => {
    return acc + next.indent;
  }, 0);
  return Array.from(Array(indent).keys())
    .map(() => " ")
    .join("");
}

export function format(src) {
  let funcBlocks = [];
  let blocks = 0;
  let inComment = false;
  let result = "";
  let inSingleQuote = false;
  let inDoubleQuote = false;

  function add(char) {
    result += char;
  }

  function next(char, index) {
    const isNewLine = !!/\r|\n/.test(char);
    const isSpace = !!/\s/.test(char) && !isNewLine;

    // quotes
    if (char === `'` && !inComment) {
      inSingleQuote = !inSingleQuote;
    }
    if (char === `"` && !inComment) {
      inDoubleQuote = !inDoubleQuote;
    }

    // don't do anything if we're in quotes
    if (inSingleQuote || inDoubleQuote) return add(char);

    // start comment
    if (char === "#" && !inComment) {
      inComment = true;
      const srcBefore = src.slice(0, index);
      const separateLine = /\n\s+$|\n$/.test(srcBefore);
      const isStartOfLine = !srcBefore;
      return add(
        `${
          separateLine
            ? `\n${buildIndent(funcBlocks)}`
            : isStartOfLine
            ? ""
            : " "
        }${char}`
      );
    }
    // remove spaces
    if (isSpace && !inComment) return;

    // end comment
    if (isNewLine && inComment) {
      inComment = false;
      const srcNext = src.slice(index + 1);
      const isClosing = /^\)|^\s+\)/.test(srcNext);
      const commentIsNext = /^#|^\s+\s+#/.test(srcNext);
      if (!isClosing && !commentIsNext) {
        return add(char + buildIndent(funcBlocks));
      } else {
        return;
      }
    }

    // remove unneeded breaks
    if (isNewLine) return;

    // if in comment, just pass character through
    if (inComment) return add(char);

    if (char === "(") {
      // function block
      const func = isFunc(result);
      if (func) {
        const argRule = argMap[func.name];
        const args = countArgs(src.slice(index));
        func.multi = args >= argRule || argRule === 0;
        func.indent = 2;

        const prevFunc = funcBlocks[funcBlocks.length - 1];
        if (prevFunc && !prevFunc.multi) {
          const test = new RegExp(
            `${prevFunc.name}\\(${func.name}|` +
              `${prevFunc.name}\\s+\\(${func.name}\\s+|` +
              `${prevFunc.name}\\(${func.name}\\s+|` +
              `${prevFunc.name}\\s+\\(${func.name}|`
          );
          if (test.test(src.slice(0, index))) {
            prevFunc.indent = 0;
          }
        }

        funcBlocks.push(func);
        const commentAfter = /^#|^\s+#/.test(src.slice(index + 1));
        if (commentAfter) {
          return add(char);
        }
        return add(func.multi ? `(\n${buildIndent(funcBlocks)}` : "(");
      }
      // regular block
      blocks++;
      return add(char);
    }

    // regular block
    if (char === ")" && blocks > 0) {
      blocks--;
      return add(char);
    }

    // function block
    if (char === ")" && blocks === 0) {
      const func = funcBlocks.pop();
      const extraBreaks =
        func.name === "var" || func.name === "evalvar" ? "\n\n" : "";
      return add(
        func.multi ? `\n${buildIndent(funcBlocks)})${extraBreaks}` : `)`
      );
    }

    // commas
    if (char === ",") {
      const func = funcBlocks[funcBlocks.length - 1];
      const commentAfter = /^\s+#|^#/.test(src.slice(index + 1));
      if (!func.multi) {
        return add(", ");
      }
      if (func.multi && !commentAfter) {
        return add(`,\n${buildIndent(funcBlocks)}`);
      }
    }

    // handle ==
    if (char === "=" && /^=|^\s=/.test(src.slice(index + 1))) {
      return add(` ${char}`);
    }

    // handle !=
    if (char === "!" && /^=|^\s+=/.test(src.slice(index + 1))) {
      return add(` ${char}`);
    }

    // handle + - * / &
    if (/[\+\-\*/&]/.test(char)) {
      // operator already found
      if (
        /\+$|\+\s+$|-$|-\s+$|\*$|\*\s+$|\/$|\/\s+$|=$|=\s+$|&$|&\s+$|>$|>\s+$|<$|<\s+$/.test(
          src.slice(0, index)
        )
      ) {
        return add(char);
      }
      return add(` ${char} `);
    }

    // handle = after first part of operator
    if (char === "=") {
      if (
        /<$|<\s+$|>$|>\s+$|!$|!\s+$|=$|=\s+$/.test(src.slice(index - 1, index))
      ) {
        return add(`${char} `);
      } else {
        return add(` ${char} `);
      }
    }

    // handle < and >
    if (/[<>]/.test(char)) {
      // handle <>
      if (char === "<" && /^\s+>|^>/.test(src.slice(index + 1))) {
        return add(` ${char}`);
      }
      // handle greater than or equal
      if (char === ">" && /<$|<\s+$/.test(src.slice(0, index))) {
        return add(`${char} `);
      }
      if (/^=|^\s+=/.test(src.slice(index + 1))) {
        return add(` ${char}`);
      }
      return add(` ${char} `);
    }

    // if we did nothing, just return the character
    return add(char);
  }

  // iterate through all characters
  for (let i = 0; i < src.length; i++) {
    next(src[i], i);
  }
  return result;
}
