// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-namespace-bank-style__container {
  border: 1px solid #dfdfdf;
  border-right: none;
  border-left: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.src-namespace-bank-style__namespaces {
  font-family: monospace;
  padding: 2px;
  display: flex;
}

.src-namespace-bank-style__namespacesExpanded {
  display: block;
}

.src-namespace-bank-style__showMore {
  text-align: right;
  margin-right: 36px;
  font-size: 12px;
  padding-left: 24px;
  white-space: nowrap;
}

.src-namespace-bank-style__namespaceBtn {
  padding: 2px;
  margin: 0 8px;
  border-radius: 5px;
  cursor: pointer;
}

.src-namespace-bank-style__namespaceBtnExpanded {
  display: inline-block;
}

.src-namespace-bank-style__namespaceBtn:hover {
  background-color: #dfdfdf;
}
`, "",{"version":3,"sources":["webpack://./src/namespace-bank.style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container {\n  border: 1px solid #dfdfdf;\n  border-right: none;\n  border-left: none;\n  flex-shrink: 0;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.namespaces {\n  font-family: monospace;\n  padding: 2px;\n  display: flex;\n}\n\n.namespacesExpanded {\n  display: block;\n}\n\n.showMore {\n  text-align: right;\n  margin-right: 36px;\n  font-size: 12px;\n  padding-left: 24px;\n  white-space: nowrap;\n}\n\n.namespaceBtn {\n  padding: 2px;\n  margin: 0 8px;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.namespaceBtnExpanded {\n  display: inline-block;\n}\n\n.namespaceBtn:hover {\n  background-color: #dfdfdf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-namespace-bank-style__container`,
	"namespaces": `src-namespace-bank-style__namespaces`,
	"namespacesExpanded": `src-namespace-bank-style__namespacesExpanded`,
	"showMore": `src-namespace-bank-style__showMore`,
	"namespaceBtn": `src-namespace-bank-style__namespaceBtn`,
	"namespaceBtnExpanded": `src-namespace-bank-style__namespaceBtnExpanded`
};
export default ___CSS_LOADER_EXPORT___;
