import React from "react";
import { CpLoader } from "canopy-styleguide!sofe";
import { Modal } from "react-disposable-modal";
import { catchAsyncStacktrace } from "auto-trace";
import AsyncDecorator from "async-decorator/rx6";
import { get } from "lodash";
import { fromEvent } from "rxjs";

import { searchTasks } from "./tasks.resource.js";
import styles from "./task-selector.styles.css";
import {
  BEGIN_TASK_SEARCH,
  getRecentTasks,
  NoSearchResults,
  RecentTasksList,
  SearchResults,
  setRecentTasks,
} from "./task-selector.helper.js";

@AsyncDecorator
export default class TaskSelector extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hoverIndex: -1,
      keyboard: false,
      loading: true,
      placeholderText: this.getPlaceholderText(props),
      searchString: "",
      showResultsDialog: false,
      tasks: [],
    };
  }

  componentDidMount() {
    const { cancelWhenUnmounted } = this.props;
    const observables = searchTasks(this.input);

    cancelWhenUnmounted(
      observables.arrows.subscribe((keyCode) => {
        const taskSource = this.state.searchString
          ? this.state.tasks
          : getRecentTasks(window.loggedInUser.id);
        if (keyCode === 38) {
          // UP ARROW key
          this.setState({
            hoverIndex: !this.state.hoverIndex
              ? this.state.hoverIndex
              : this.state.hoverIndex - 1,
            keyboard: true,
          });
        } else if (keyCode === 40) {
          // DOWN ARROW key
          this.setState({
            hoverIndex:
              this.state.hoverIndex >= taskSource.length - 1
                ? taskSource.length - 1
                : this.state.hoverIndex + 1,
            keyboard: true,
          });
        } else if (keyCode === 13) {
          // ENTER key
          if (taskSource.length > 0 && this.state.hoverIndex >= 0) {
            this.setSelectedTaskCallback(taskSource[this.state.hoverIndex]);
          } else if (taskSource.length === 1) {
            this.setSelectedTaskCallback(taskSource[0]);
          } else {
            // Pressing the enter key is expressing intent
            this.setSelectedTaskCallback(taskSource[0]);
          }
        } else {
          this.hideSearchResults();
        }
      }, catchAsyncStacktrace())
    );

    cancelWhenUnmounted(
      observables.empty.subscribe(
        () => this.setState({ tasks: [] }),
        catchAsyncStacktrace()
      )
    );

    cancelWhenUnmounted(
      observables.results.subscribe((tasks) => {
        this.setState({
          tasks,
          loading: false,
        });
      }, catchAsyncStacktrace())
    );

    cancelWhenUnmounted(
      fromEvent(window, BEGIN_TASK_SEARCH).subscribe(this.setLoadingState),
      fromEvent(document, "click").subscribe(this.handleClick)
    );
  }

  render() {
    return (
      <div
        id="papaDiv"
        ref={(overallDialog) => {
          this.overallDialog = overallDialog;
        }}
      >
        <input
          type="text"
          data-testid="search-text"
          ref={(input) => {
            this.input = input;
          }}
          value={this.state.searchString}
          className="cps-form-control"
          onChange={this.handleChange}
          onFocus={this.showResultsDialog}
          placeholder={this.state.placeholderText}
        />
        {this.state.showResultsDialog && this.getSearchResults()}
      </div>
    );
  }

  getSearchResults = () => {
    const rect = this.input.getBoundingClientRect();
    const MODAL_WIDTH = 320;
    const MODAL_HEIGHT = 390;
    const PADDING = 36;
    const left =
      document.documentElement.clientWidth -
      rect.left -
      rect.width +
      MODAL_WIDTH +
      PADDING;

    return (
      <Modal dialogId="resultsDialog">
        <ul
          className={`cps-dropdown-menu ${styles.menu}`}
          role="menu"
          style={{
            width: `${MODAL_WIDTH}px`,
            maxHeight: `${MODAL_HEIGHT}px`,
            left: `calc(100% - ${left}px)`,
            top: rect.bottom,
            zIndex: 100002,
          }}
        >
          {this.state.showResultsDialog && this.populateModal()}
        </ul>
      </Modal>
    );
  };

  handleChange = (e) => {
    this.setState({
      hoverIndex: -1,
      loading: true,
      searchString: e.target.value,
      tasks: [],
    });
  };

  showResultsDialog = () => {
    this.setState(() => ({
      placeholderText: "",
      showResultsDialog: true,
    }));
  };

  hideSearchResults = () => {
    this.setState({
      hoverIndex: -1,
      loading: true,
      placeholderText: this.getPlaceholderText(this.props),
      searchString: "",
      showResultsDialog: false,
      tasks: [],
    });
  };

  setSelectedTaskCallback = (taxPrepTask) => {
    setRecentTasks(window.loggedInUser.id, taxPrepTask);
    this.props.setTaxPrepTask(taxPrepTask);
    this.hideSearchResults();
  };

  setHoveredIndex = (index) => {
    this.setState({
      hoverIndex: index,
    });
  };

  changeToMouseInput = (evt) => {
    this.setState({ keyboard: false });
  };

  populateModal = () => {
    const { hoverIndex, searchString } = this.state;

    switch (searchString.trim().length) {
      case 0:
        return (
          <RecentTasksList
            hideSearch={this.hideSearchResults}
            hoverIndex={hoverIndex}
            setHoveredIndex={this.setHoveredIndex}
            setSelectedTaskCallback={this.setSelectedTaskCallback}
            userId={this.props.userId}
          />
        );

      case 1:
        return <NoSearchResults />;

      default:
        return this.searchTaskResults();
    }
  };

  searchTaskResults = () => {
    const { hoverIndex, keyboard, loading, searchString, tasks } = this.state;

    return loading ? (
      <CpLoader />
    ) : tasks.length ? (
      <SearchResults
        handleScroll={this.changeToMouseInput}
        hideSearch={this.hideSearchResults}
        hoverIndex={hoverIndex}
        isKeyboard={keyboard}
        searchString={searchString}
        setHoveredIndex={this.setHoveredIndex}
        setSelectedTaskCallback={this.setSelectedTaskCallback}
        tasks={tasks}
      />
    ) : (
      <NoSearchResults />
    );
  };

  handleClick = (e) => {
    if (this.overallDialog.contains(e.target)) {
      return;
    }

    this.hideSearchResults();
  };

  setLoadingState = () => {
    this.setState({ loading: true });
  };

  getPlaceholderText = (props) => {
    return get(props, "taxPrepTask.name", "Search tax prep tasks...");
  };
}
