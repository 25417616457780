// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-tests-create-edit-test-style__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.src-tests-create-edit-test-style__testForm {
  flex-grow: 1;
  padding: 16px;
  padding-bottom: 92px;
  border: 1px solid #dfdfdf;
  border-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.src-tests-create-edit-test-style__actions {
  padding: 16px;
  border: 1px solid #dfdfdf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  background: white;
}

.src-tests-create-edit-test-style__namespaceCol {
  width: 40%;
}

.src-tests-create-edit-test-style__outputCol {
  width: 40%;
  margin-left: 60px;
  padding-left: 24px;
  border-left: 1px solid #dfdfdf;
}
`, "",{"version":3,"sources":["webpack://./src/tests/create-edit-test.style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,yBAAyB;EACzB,gBAAgB;EAChB,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,8BAA8B;EAC9B,+BAA+B;EAC/B,kBAAkB;EAClB,SAAS;EACT,wBAAwB;EACxB,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;EAClB,8BAA8B;AAChC","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.testForm {\n  flex-grow: 1;\n  padding: 16px;\n  padding-bottom: 92px;\n  border: 1px solid #dfdfdf;\n  border-bottom: 0;\n  border-top-left-radius: 5px;\n  border-top-right-radius: 5px;\n}\n\n.actions {\n  padding: 16px;\n  border: 1px solid #dfdfdf;\n  border-bottom-left-radius: 5px;\n  border-bottom-right-radius: 5px;\n  position: absolute;\n  bottom: 0;\n  width: calc(100% - 48px);\n  background: white;\n}\n\n.namespaceCol {\n  width: 40%;\n}\n\n.outputCol {\n  width: 40%;\n  margin-left: 60px;\n  padding-left: 24px;\n  border-left: 1px solid #dfdfdf;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `src-tests-create-edit-test-style__container`,
	"testForm": `src-tests-create-edit-test-style__testForm`,
	"actions": `src-tests-create-edit-test-style__actions`,
	"namespaceCol": `src-tests-create-edit-test-style__namespaceCol`,
	"outputCol": `src-tests-create-edit-test-style__outputCol`
};
export default ___CSS_LOADER_EXPORT___;
