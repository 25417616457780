import { each, throttle, without } from "lodash";

let listeners = {
  mousemove: [],
  mouseup: [],
};

if (typeof document === "object") {
  document.addEventListener(
    "mousemove",
    throttle((event) => {
      each(listeners.mousemove, (listener) => {
        listener.call(null, event);
      });
    }, 50)
  );

  document.addEventListener(
    "mouseup",
    throttle((event) => {
      each(listeners.mouseup, (listener) => {
        listener.call(null, event);
      });
    }, 50)
  );
}

export function stopPropagation(f) {
  return (...args) => {
    args[0].stopPropagation();
    f(...args);
  };
}

export function preventDefault(f) {
  return (...args) => {
    args[0].preventDefault();
    f(...args);
  };
}

export default {
  on: function (type, callback) {
    listeners[type].push(callback);
  },

  off: function (type, callback) {
    listeners[type] = without(listeners[type], callback);
  },
};
