import React from "react";
import styles from "src/namespace-bank.style.css";
import { partial } from "lodash";

export default class NamespaceBank extends React.Component {
  state = {
    expanded: false,
  };

  render() {
    return (
      <div className={styles.container}>
        <div
          className={`${styles.namespaces} ${
            this.state.expanded ? styles.namespacesExpanded : "cps-ellipsis"
          }`}
        >
          {this.props.namespaces.map((namespace) => (
            <div
              key={namespace}
              onMouseDown={partial(this.props.insertText, namespace)}
              className={`${styles.namespaceBtn} ${
                this.state.expanded ? styles.namespaceBtnExpanded : ""
              }`}
            >
              {namespace}
            </div>
          ))}
        </div>
        {!this.state.expanded && (
          <div className={styles.showMore}>
            <a onMouseDown={this.expand}>Show all</a>
          </div>
        )}
      </div>
    );
  }

  expand = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      expanded: true,
    });
  };
}
