import React from "react";
import {
  isBoolean,
  isEmpty,
  isNull,
  isNumber,
  isPlainObject,
  isString,
  isUndefined,
  reduce,
} from "lodash";

export const SELECTED_TAX_FORM_TASK_KEY = "sme_logic_al_tax_prep_task";

export const formatValue = (value) => {
  if (isNull(value)) {
    return "null";
  }

  if (isUndefined(value)) {
    return "undefined";
  }

  if (isBoolean(value) || isNumber(value)) {
    return value.toString();
  }

  if (Array.isArray(value) || isString(value)) {
    return JSON.stringify(value);
  }

  if (isEmpty(value)) {
    return <span>&mdash;</span>;
  }

  if (isPlainObject(value) && !React.isValidElement(value)) {
    let stringifiedValue = "[Circular]";

    try {
      stringifiedValue = JSON.stringify(value, null, 2);
    } catch (error) {
      window.console.log("value: ", value);
    }

    return stringifiedValue;
  }

  return value;
};

export const convertDebugObjectToArrayOfObjects = (debugObj = {}) => {
  let returnArray = reduce(
    debugObj,
    (acc, val, key) => {
      acc.push({
        key,
        val,
      });

      return acc;
    },
    []
  );

  returnArray.sort((a, b) => {
    const valA = a.key.toUpperCase();
    const valB = b.key.toUpperCase();

    if (valA < valB) return -1;
    if (valA > valB) return 1;

    return 0;
  });

  return returnArray;
};

export const getRuleDebugData = (fullDebugData, uniqueNamespaces) =>
  uniqueNamespaces.map(
    (key) =>
      fullDebugData.find(
        (datum) => datum.key.toLowerCase() === key.toLowerCase()
      ) || { key, val: <span>&mdash;</span> }
  );

export const getDataBasedOnCurrentTab = ({
  activeTabIndex,
  fullDebugData,
  ruleDebugData,
}) => (activeTabIndex === 0 ? ruleDebugData : fullDebugData);

export const getDataForTable = (state = {}) =>
  state.filteredDebug.length
    ? state.filteredDebug
    : getDataBasedOnCurrentTab(state);

export const getTaxPrepTask = () =>
  JSON.parse(localStorage.getItem(SELECTED_TAX_FORM_TASK_KEY));
