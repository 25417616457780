// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-inspector-task-selector-styles__resultHeader {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 12px;
}

.src-inspector-task-selector-styles__taskList {
  overflow-y: auto;
}

.src-inspector-task-selector-styles__menu {
  text-align: left;
  display: block;
  position: fixed;
}

.src-inspector-task-selector-styles__sizeHandle {
  height: 100%;
  background: white;
  width: 4px;
  cursor: col-resize;
}
`, "",{"version":3,"sources":["webpack://./src/inspector/task-selector.styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,UAAU;EACV,kBAAkB;AACpB","sourcesContent":[":local(.resultHeader) {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding: 4px 12px;\n}\n\n:local(.taskList) {\n  overflow-y: auto;\n}\n\n:local(.menu) {\n  text-align: left;\n  display: block;\n  position: fixed;\n}\n\n:local(.sizeHandle) {\n  height: 100%;\n  background: white;\n  width: 4px;\n  cursor: col-resize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resultHeader": `src-inspector-task-selector-styles__resultHeader`,
	"taskList": `src-inspector-task-selector-styles__taskList`,
	"menu": `src-inspector-task-selector-styles__menu`,
	"sizeHandle": `src-inspector-task-selector-styles__sizeHandle`
};
export default ___CSS_LOADER_EXPORT___;
