export const numberValues = (val) => {
  let fParsed = parseFloat(val);
  const dblQuotesRegex = /"([^"+])"/;
  const truRegex = /\btrue\b/gi;
  const falseRegex = /\bfalse\b/gi;

  switch (true) {
    case truRegex.test(val):
      return true;
    case falseRegex.test(val):
      return false;
    case val == fParsed && !dblQuotesRegex.test(val):
      return fParsed;
    default:
      return val;
  }
};
