import React from "react";

export default class TestsStatus extends React.PureComponent {
  render() {
    const numFailed = this.props.testRuns.filter(
      (test) => test.testResult === "failed"
    ).length;

    return (
      <div
        className="cps-padding-right-24"
        style={{ textAlign: "right", display: "flex", alignItems: "center" }}
      >
        {numFailed === 0 ? (
          <span className="cps-success cps-caption">All tests passing</span>
        ) : (
          <span style={{ display: "flex", alignItems: "center" }}>
            <span
              className="cps-icon cps-icon-refresh"
              style={{ cursor: "pointer" }}
              onClick={this.props.refreshTestRuns}
            />
            <span className="cps-warning cps-caption">
              {numFailed} test
              {numFailed === 1 ? "" : "s"} not passing
            </span>
          </span>
        )}
      </div>
    );
  }
}
