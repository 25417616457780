import fetcher from "src/common/fetcher.helper.js";
import { pluck } from "rxjs/operators";
import { adjustPath } from "src/test-resolution.helper.js";

export function getTests(path) {
  const adjustedPath = adjustPath(path);
  return fetcher(`formula-tests?path=${btoa(adjustedPath)}`).pipe(
    pluck("formula-tests")
  );
}

export function createTest(data) {
  return fetcher("formula-tests", {
    method: "POST",
    body: { "formula-tests": { ...data, path: adjustPath(data.path) } },
  }).pipe(pluck("formula-tests"));
}

export function updateTest(id, data) {
  return fetcher(`formula-tests/${id}`, {
    method: "PUT",
    body: { "formula-tests": { ...data, path: adjustPath(data.path) } },
  }).pipe(pluck("formula-tests"));
}

export function deleteTest(id) {
  return fetcher(`formula-tests/${id}`, {
    method: "DELETE",
  }).pipe(pluck("formula-tests"));
}

export function runTests(path, override) {
  return fetcher(`formula-test-runs?path=${path}`, {
    method: "POST",
    body: {
      "formula-test-runs": {
        path: adjustPath(path),
        override,
      },
    },
  }).pipe(pluck("formula-test-runs"));
}

export function runTest(id) {
  return fetcher(`formula-test-runs/${id}`, {
    method: "POST",
  }).pipe(pluck("formula-test-runs"));
}

export function executeExpression({
  expression,
  inputs = {},
  name,
  taxFormId = "null",
}) {
  const uri = taxFormId
    ? `tax-forms/${taxFormId}/rules-validation`
    : `rules-validation`;
  return fetcher(uri, {
    method: "POST",
    body: {
      "rules-validation": {
        expression,
        assignment: name,
        inputData: inputs,
      },
    },
  }).pipe(pluck("rules-validation"));
}
