import React from "react";
import { CpEmptyState } from "canopy-styleguide!sofe";

export default function LogicAlEmptyState(props) {
  return (
    <CpEmptyState
      className="cp-mv-16"
      text="Select a Tax Prep Task to start the Logic Assistant!"
      img="es_logic_assistant"
      subText="The Logic Assistant will show you details of variables from a tax prep task."
    />
  );
}
