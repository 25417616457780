import React from "react";
import styles from "src/tests/tests-list.style.css";
import TestRow from "src/tests/test-row.component.js";
import { partial } from "lodash";

export default class TestsList extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        {this.props.testRuns &&
          this.props.testRuns.map((testRun) => (
            <div key={testRun.id}>
              <TestRow
                testRun={testRun}
                expandTest={partial(this.props.expandTest, testRun.id)}
                cloneTest={partial(this.props.cloneTest, testRun.id)}
                deleteTest={partial(this.props.deleteTest, testRun.id)}
              />
            </div>
          ))}
        <div className={styles.newRow}>
          <a
            className="cps-primary-green cps-wt-bold"
            onClick={this.props.addNewTest}
          >
            + new test
          </a>
        </div>
      </div>
    );
  }
}
