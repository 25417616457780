import React from "react";
import { AutoSizer, Column, Table } from "react-virtualized";

import { formatValue } from "./inspector.helper.js";
import { styleMatchingText } from "./task-selector.helper.js";

import styles from "./tabular-data.styles.css";
import "react-virtualized/styles.css";

export default class TabularData extends React.PureComponent {
  render() {
    const { currentVariable, tabularData } = this.props;

    return (
      <div
        className="cps-padding-bottom-24"
        style={{
          display: "inline-block",
          height: "calc(100%)",
          width: "100%",
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Table
              headerHeight={40}
              height={height}
              ref={(el) => {
                this.tableEl = el;
              }}
              rowClassName={({ index }) =>
                index >= 0 &&
                currentVariable &&
                tabularData[index] &&
                tabularData[index].key
                  .toLowerCase()
                  .includes(currentVariable.toLowerCase())
                  ? styles.highlightedRow
                  : ""
              }
              rowCount={tabularData.length}
              rowGetter={({ index }) => tabularData[index]}
              rowHeight={40}
              rowStyle={({ index }) => ({
                borderBottom:
                  index === -1 || index === tabularData.length - 1
                    ? "none"
                    : "1px solid #E9E9E9",
              })}
              width={width}
            >
              <Column
                cellRenderer={this.renderCell}
                dataKey="key"
                disableSort={true}
                flexGrow={0}
                headerRenderer={this.renderColumnHeader}
                label="Variables"
                width={width / 2}
              />
              <Column
                cellRenderer={this.renderCell}
                dataKey="val"
                disableSort={true}
                flexGrow={0}
                headerRenderer={this.renderColumnHeader}
                label="Values"
                width={width / 2}
              />
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  }

  renderColumnHeader = ({ label }) => (
    <div
      className="cps-body cps-wt-bold"
      style={{ textTransform: "capitalize" }}
    >
      {label}
    </div>
  );

  renderCell = ({ cellData, columnIndex }) => {
    return columnIndex > 0 ? (
      <div>
        {styleMatchingText(formatValue(cellData), this.props.filterString)}
      </div>
    ) : (
      <div>{styleMatchingText(cellData, this.props.filterString)}</div>
    );
  };
}
