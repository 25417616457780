import React from "react";
import { CprTooltip } from "canopy-styleguide!sofe";
import { isEmpty } from "lodash";
import styles from "src/tests/test-row.style.css";

export default class TestRow extends React.PureComponent {
  render() {
    return (
      <div className={styles.row}>
        {this.props.testRun.label}
        <div>
          <span className="cps-margin-right-12">
            {this.props.testRun.testResult}
          </span>
          {this.actions()}
        </div>
      </div>
    );
  }

  actions() {
    return (
      <div className="cps-btn-icon">
        <CprTooltip
          html={
            isEmpty(this.props.testRun.actualResult)
              ? "(empty)"
              : this.props.testRun.actualResult
          }
        >
          <a className="cps-link">
            <span className="cps-icon cps-icon-visible" />
          </a>
        </CprTooltip>
        <CprTooltip html="Clone">
          <a className="cps-link" onClick={this.props.cloneTest}>
            <span className="cps-icon cps-icon-duplicate" />
          </a>
        </CprTooltip>
        <CprTooltip html="Edit">
          <a className="cps-link" onClick={this.props.expandTest}>
            <span className="cps-icon cps-icon-notes" />
          </a>
        </CprTooltip>
        <CprTooltip html="Delete">
          <a className="cps-link" onClick={this.props.deleteTest}>
            <span className="cps-icon cps-icon-trash" />
          </a>
        </CprTooltip>
      </div>
    );
  }
}
