import React from "react";
import { find, findIndex, get, property, set } from "lodash";
import { CpSelectSingle } from "canopy-styleguide!sofe";
import { infoToast } from "toast-service!sofe";
import styles from "src/addendum-logic.style.css";

export default class AddendumLogic extends React.Component {
  constructor(props) {
    super();

    this.state = {
      element: {
        rule: props.rule,
        name: props.name,
        pageLocation: props.taxFormOptions.pageLocation,
      },
      taxFormOptions: props.taxFormOptions,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.name !== prevProps.name) {
      this.setState({
        element: {
          rule: this.props.rule,
          name: this.props.name,
          pageLocation: this.props.taxFormOptions.pageLocation,
        },
        taxFormOptions: this.props.taxFormOptions,
      });
    }
  }

  render() {
    const { element, taxFormOptions } = this.state;
    const { addendums, selectedAddendum, isAddendum } = taxFormOptions;

    const changeSelectedAddendum = (selectedAddendum) => {
      this.props.updateReturnObject(selectedAddendum ? selectedAddendum : null);
      taxFormOptions.changeSelectedAddendum(selectedAddendum);

      this.setState({
        taxFormOptions: {
          ...taxFormOptions,
          selectedAddendum,
        },
      });
    };

    const changeAddendumFieldOptions = (options) => {
      taxFormOptions.changeAddendumFieldOptions(options);
      this.setState({
        element: {
          ...element,
          ...options,
        },
      });
    };

    const updateAddendum = (addendum) => {
      addendums[findIndex(addendums, { name: selectedAddendum })] = addendum;

      this.setState({
        taxFormOptions: {
          ...taxFormOptions,
          addendums,
        },
      });

      this.props.updateReturnObject(addendum.name);
      taxFormOptions.updateAddendum(addendum);
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.container}>
          {isAddendum
            ? null
            : getAddendumSelect(
                addendums,
                selectedAddendum,
                changeSelectedAddendum
              )}
          {isAddendum || !selectedAddendum
            ? null
            : getAddendumType(
                element,
                addendums,
                selectedAddendum,
                updateAddendum
              )}
          {isAddendum
            ? getAddendumFieldOptions(element, changeAddendumFieldOptions)
            : null}
          {isAddendum
            ? null
            : getAddendumDisplayOptions(
                addendums,
                selectedAddendum,
                updateAddendum
              )}
        </div>
      </div>
    );
  }
}

function getAddendumSelect(
  addendums,
  selectedAddendum,
  changeSelectedAddendum
) {
  return (
    <div className="cps-form-group">
      <label className="cps-control-label cps-margin-bottom-4 cps-padding-top-0">
        Connected Addendum
      </label>
      <div style={{ width: "300px" }}>
        <CpSelectSingle
          data={getOptions(addendums)}
          placeholder="Choose addendum"
          value={{ key: selectedAddendum, value: selectedAddendum || "None" }}
          onChange={(addendum) => changeSelectedAddendum(addendum.key)}
          transformData={(addendum) => ({
            ...addendum,
            name: addendum.value,
            id: addendum.value,
          })}
        />
      </div>
    </div>
  );

  function getOptions(addendums) {
    const list = (addendums || []).map((addendum) => ({
      value: addendum.name,
      key: addendum.name,
    }));
    list.unshift({ value: "None", key: null });

    return list;
  }
}

function getAddendumType(element, addendums, selectedAddendum, updateAddendum) {
  const addendum = find(addendums, { name: selectedAddendum });

  if (!addendum) return null;

  function changeType(event) {
    const type = event.target.value;

    if (type === "table" && !fieldIsAListElement(element.name)) {
      infoToast(
        `This field ${element.name} is not named with an "_" and cannot be used for a table addendum`,
        "I apologize for trying"
      );
    } else {
      let addendum = find(addendums, { name: selectedAddendum });
      addendum.addendumType = type;
      updateAddendum(addendum);
    }
  }

  function changeOverflowOption(event) {
    addendum.addendumOptions = {
      ...(addendum.addendumOptions || {}),
      noOverflow: event.target.checked,
    };

    updateAddendum(addendum);
  }

  return (
    <div>
      <div className="cps-form-group">
        <label className="cps-radio">
          <input
            type="radio"
            value="text"
            checked={addendum.addendumType === "text"}
            onChange={changeType}
          />
          <span>Text</span>
        </label>
        <label className="cps-radio">
          <input
            type="radio"
            value="table"
            checked={addendum.addendumType === "table"}
            onChange={changeType}
          />
          <span>Table</span>
        </label>
      </div>
      <div
        className="cps-form-group"
        title="Prevent table addendums from overflowing all elements to the addendum. Keep some on the tax form."
      >
        <label className="cps-control-label cps-margin-right-8">
          No Overflow
        </label>
        <label className="cps-checkbox">
          <input
            type="checkbox"
            checked={property("addendumOptions.noOverflow")(addendum)}
            onChange={changeOverflowOption}
          />
          <span />
        </label>
      </div>
    </div>
  );
}

function getAddendumFieldOptions(element, changeAddendumFieldOptions) {
  return (
    <div className="cps-form-group">
      <label className="cps-control-label">Addendum page</label>
      <CpSelectSingle
        data={getOptions()}
        placeholder="Select page"
        value={{
          id: element.pageLocation,
          name: element.pageLocation,
        }}
        onChange={(location) => {
          changeAddendumFieldOptions({
            pageLocation: location.id,
          });
        }}
      />
    </div>
  );

  function getOptions() {
    return [
      { id: "all", name: "All" },
      { id: "first", name: "First" },
      { id: "last", name: "Last" },
    ];
  }
}

function getAddendumDisplayOptions(
  addendums,
  selectedAddendum,
  updateAddendum
) {
  const addendum = find(addendums, { name: selectedAddendum });

  if (!addendum) return null;

  function changeDisplay(event) {
    const display = event.target.checked;

    let addendum = find(addendums, { name: selectedAddendum });
    set(addendum, "addendumOptions.hideOverflowText", display);
    updateAddendum(addendum);
  }

  return (
    <div className="cps-form-group">
      <label className="cps-control-label">Display options:</label>
      <div className="cps-form-group">
        <label className="cps-checkbox">
          <input
            type="checkbox"
            value="hideOverflowText"
            checked={get(addendum, "addendumOptions.hideOverflowText")}
            onChange={changeDisplay}
          />
          <span>Hide &quot;see attached&quot;</span>
        </label>
      </div>
    </div>
  );
}

function fieldIsAListElement(name) {
  const index = name.lastIndexOf("_");
  const num = name.substring(index + 1);

  return num.match(/^\d+$/g);
}
