import React, { createRef } from "react";
import { CpButton, CpSelectSingle } from "canopy-styleguide!sofe";
import { debounce, isEmpty, map, mapValues, partial } from "lodash";
import { map as rxjs_map } from "rxjs/operators";
import { forkJoin } from "rxjs";
import { catchAsyncStacktrace } from "auto-trace";
import {
  getQuestionsByString,
  getSharedValueIndex,
} from "./search.resource.js";

export default class InputBuilder extends React.PureComponent {
  state = {
    addingNewNamespace: isEmpty(this.props.inputData), // Start out adding a new one if empty
    namespaces: [],
    search: "",
    searchLoading: false,
  };
  render() {
    return (
      <div>
        {map(this.props.inputData, this.toNamespaceInput)}
        {this.state.addingNewNamespace ? (
          this.newNamespaceInput()
        ) : (
          <CpButton
            className="cp-mt-24"
            btnType="secondary"
            onClick={this.startAddingNamespace}
          >
            Add namespace
          </CpButton>
        )}
      </div>
    );
  }

  toNamespaceInput = (value, namespace, inputData) => {
    return (
      <div className="cp-mb-12" key={namespace}>
        <label htmlFor="namespace">{namespace}</label>
        <div style={{ display: "flex", alignItems: "center" }}>
          <input
            id="namespace"
            type="text"
            className="cps-form-control"
            placeholder="Possible value"
            value={value}
            onChange={partial(this.handleNamespaceValueChange, namespace)}
            ref={(el) => (this[namespace] = el)}
          />
          <div className="cps-btn-icon">
            <a
              className="cps-link"
              onClick={partial(this.removeNamespace, namespace)}
            >
              <span className="cps-icon cps-icon-trash" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  newNamespaceInput = () => {
    return (
      <div>
        <label htmlFor="newNamespace">Namespace</label>
        <div style={{ display: "flex" }}>
          <CpSelectSingle
            data={this.state.namespaces}
            placeholder="Namespaces"
            value=""
            searchOnChange={this.searchOnChange}
            searchValue={this.state.search}
            loading={this.state.searchLoading}
            onChange={(namespace) => {
              this.addNamespace(namespace.id);
            }}
            transformData={(namespace) => ({
              id: namespace,
              name: namespace,
            })}
          />
        </div>
      </div>
    );
  };

  startAddingNamespace = () => {
    this.setState({
      addingNewNamespace: true,
    });
  };

  addNamespace = (val) => {
    if (val) {
      this.props.updateInputData(val.value);
    }
    this.setState(
      {
        addingNewNamespace: false,
      },
      () => {
        val && this[val.value].focus();
      }
    );
  };

  removeNamespace = (namespace) => {
    this.props.removeInputData(namespace);
  };

  handleNamespaceValueChange = (namespace, e) => {
    // Update the value of the given namespace, handling the input change event
    const newInputData = mapValues(this.props.inputData, (value, ns) =>
      ns === namespace ? e.target.value : value
    );
    this.props.updateInputDataValue(newInputData);
  };

  debounceSearchRef = debounce((s) => this.getNamespaceOptions(s), 400);

  searchOnChange = (search) => {
    this.setState({ search });
    this.debounceSearchRef(search);
  };

  getNamespaceOptions = (search) => {
    this.setState({ searchLoading: true });
    if (search.length < 3)
      return this.setState({
        namespaces: [],
      });

    forkJoin(getQuestionsByString(search), getSharedValueIndex())
      .pipe(
        rxjs_map((resp) => {
          const questions = resp[0];
          const sharedValues = resp[1];
          return [
            ...questions.map((question) => question.name),
            ...sharedValues.map((value) => value.key),
          ];
        })
      )
      .subscribe((namespaces) => {
        this.setState({
          namespaces,
          searchLoading: false,
        });
      }, catchAsyncStacktrace());
  };
}
