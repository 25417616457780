import { isEmpty } from "lodash";

export const getVersionId = (
  previousVersion = false,
  location = window.location.hash
) => {
  const re = /\/versions\/((\w|\-|\d)*)(?:\/|\?)?/g;
  const result = re.exec(location);

  if (isEmpty(result)) return null;

  let versionId = result[1];

  if (previousVersion) {
    let rev = Number(versionId.split("-")[1]);
    rev = rev === 0 ? rev : rev - 1;
    versionId = versionId.split("-")[0] + "-" + rev;
  }

  return versionId;
};

export const getTaxFormId = (location = window.location.hash) => {
  const re = /\/tax-forms\/(?:edit\/)?((\w|\d)+)(?:\/|\?)?/g;
  const result = re.exec(location);

  if (isEmpty(result)) return null;

  let taxFormId = ["folders"].includes(result[1]) ? null : result[1];

  return taxFormId;
};
