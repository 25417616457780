import React from "react";
import { isEmpty, partial, noop, mapValues } from "lodash";
import { CpButton } from "canopy-styleguide!sofe";
import { catchAsyncStacktrace } from "auto-trace";

import CreateEditTest from "src/tests/create-edit-test.component.js";
import TestsList from "src/tests/tests-list.component.js";
import {
  createTest,
  updateTest,
  deleteTest,
} from "src/tests/tests.resource.js";
import { numberValues } from "src/common/numberValues.helper.js";

import styles from "src/tests-tab.style.css";

export default class TestsTab extends React.PureComponent {
  state = {
    expandedTestId: null,
  };

  render() {
    return (
      <div className={styles.container}>
        {isEmpty(this.props.tests) ? (
          this.emptyState()
        ) : this.state.expandedTestId ? (
          <div data-testid="create-edit-test">
            <CreateEditTest
              test={this.props.tests.find(
                (test) => test.id === this.state.expandedTestId
              )}
              saveTest={partial(this.saveTest, this.state.expandedTestId)}
              cancelTest={this.removeNewTest}
              namespaces={this.props.namespaces}
            />
          </div>
        ) : (
          <div data-testid="tests-list">
            <TestsList
              testRuns={this.props.testRuns}
              addNewTest={this.addNewTest}
              expandTest={this.expandTest}
              cloneTest={this.cloneTest}
              deleteTest={this.deleteTest}
            />
          </div>
        )}
      </div>
    );
  }

  emptyState = () => {
    return (
      <div className={styles.emptyState} data-testid="empty-state">
        <div className="cp-mb-24">This field doesn't have any tests ☹</div>
        <CpButton
          data-testid="add-test"
          btnType="primary"
          onClick={this.addNewTest}
        >
          + Test
        </CpButton>
      </div>
    );
  };

  addNewTest = () => {
    this.props.updateTests([
      ...this.props.tests,
      { id: "new", path: this.props.path },
    ]);
    this.setState((prevState) => ({
      expandedTestId: "new",
    }));
  };

  removeNewTest = () => {
    this.props.updateTests(
      this.props.tests.filter((test) => test.id !== "new")
    );
    this.setState({ expandedTestId: null });
  };

  expandTest = (id) => {
    this.setState({
      expandedTestId: id,
    });
  };

  cloneTest = (id) => {
    let testData = this.props.tests.find((test) => test.id === id);
    testData.label = `${testData.label} - clone`;
    testData.id = "new";
    this.saveTest(testData.id, testData);
  };

  deleteTest = (id) => {
    const notDeleted = (test) => test.id !== id;
    this.props.updateTests(this.props.tests.filter(notDeleted));
    this.props.updateTestRuns(this.props.testRuns.filter(notDeleted));
    deleteTest(id).subscribe(noop, catchAsyncStacktrace());
  };

  saveTest = (id, data) => {
    // Optimistic update in memory
    this.updateTestData(id, data);

    const success = (resp) => {
      this.updateTestData(id, resp);
      // Test was updated, re-run tests
      this.props.fetchAndRunTests();
    };

    // Persist to API and update in memory again
    if (id === "new") {
      createTest(data).subscribe(success, catchAsyncStacktrace());
    } else {
      updateTest(id, data).subscribe(success, catchAsyncStacktrace());
    }
  };

  updateTestData = (id, data) => {
    // make sure that numeric values are preserved
    let dataWithNums = {
      ...data,
      expectedResult: numberValues(data.expectedResult),
      inputData: mapValues(data.inputData, (val) => numberValues(val)),
    };

    // Update test data in memory
    this.setState({
      expandedTestId: null,
    });
    this.props.updateTests(
      this.props.tests.map((test) =>
        test.id === id ? { ...test, ...dataWithNums } : test
      )
    );
  };
}
