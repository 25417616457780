import React, { createRef } from "react";
import { Controlled as CodeMirrorComponent } from "react-codemirror2";
import { debounce, get, includes, isEmpty, keys, partial, uniq } from "lodash";
import { catchAsyncStacktrace } from "auto-trace";
import { warningToast } from "toast-service!sofe";
import {
  CpButton,
  CpEmptyState,
  CpLoader,
  CpTooltip,
  CpTabs,
  CpCheckbox,
  keydownEventStack,
} from "canopy-styleguide!sofe";

import mouseListeners from "src/common/mouse-listener.helper.js";
import { getTaxFormId } from "./logic-editor.helper.js";
import newWindowMarkup from "./new-window.js";
import UnsavedChangesDialog from "src/unsaved-changes-dialog.component.js";
import { autoFormat, setBadFields } from "src/logic-codemirror.js";
import AddendumLogic from "src/addendum-logic.component.js";
import TestsTab from "src/tests-tab.component.js";
import NamespaceBank from "src/namespace-bank.component.js";
import TestsStatus from "src/tests-status.component.js";
import ExpressionStatus from "src/expression-status.component.js";
import Inspector from "src/inspector/inspector.component.js";
import {
  executeExpression as _executeExpression,
  getTests,
  runTests as _runTests,
} from "src/tests/tests.resource.js";
import ValidationFeedback from "src/validation-feedback.component.js";

import styles from "src/logic-editor.style.css";

const INITIAL_SIZE = 500;

export default class LogicEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "logic",
      allLogicNamespaces: [],
      anchor: null,
      detached: false, // a separate window
      displayed: false,
      dragging: false,
      expressionResults: null,
      logicEditorOptions: null,
      name: "",
      originalRule: "",
      rule: "",
      showInspector: false,
      size: INITIAL_SIZE,
      tempSize: 0,
      tests: [],
      uniqueNamespaces: [],
      unsavedChangesDialog: false,
      currentVariable: "",
      validating: false,
      saveBtnDisabled: false,
      formulaError: null,
    };

    this.debouncedExecuteExpression = debounce(this.executeExpression, 1000);
    this.escKeydownRef = createRef();
    this.crtlXKeydownRef = createRef();
    this.crtlSKeydownRef = createRef();
  }

  componentDidMount() {
    mouseListeners.on("mousemove", this.resize);
    mouseListeners.on("mouseup", this.stopDrag);
  }

  componentWillUnmount() {
    mouseListeners.off("mousemove", this.resize);
    mouseListeners.off("mouseup", this.stopDrag);
    if (this.fetchingReferencesSubscription) {
      this.fetchingReferencesSubscription.unsubscribe();
    }
  }

  initialize(data) {
    // close logic editor but still show save dialog if changes have been made
    this.escKeydownRef.current = keydownEventStack.add({
      keys: ["Escape", "Esc"],
      callback: this.closeLogicEditorTest,
    });
    // close logic editor without saving control/windows btn + shift + X for windows or command + shift + S for mac
    this.crtlXKeydownRef.current = keydownEventStack.add({
      keys: ["X", "x"],
      callback: (e) => {
        if ((e.ctrlKey && e.shiftKey) || (e.metaKey && e.shiftKey)) {
          this.closeLogicEditor();
          e.preventDefault();
        }
      },
    });
    // save and close editor control/windows btn + shift + S for windows or command + shift + S for mac
    this.crtlSKeydownRef.current = keydownEventStack.add({
      keys: ["S", "s"],
      callback: (e) => {
        if ((e.ctrlKey && e.shiftKey) || (e.metaKey && e.shiftKey)) {
          if (this.state.formulaError) {
            warningToast("Unable to save because your formula is invalid");
          } else {
            this.saveLogicAndClose();
          }
          e.preventDefault();
        }
      },
    });

    if (this.window) {
      this.newWindowLogicClosed = false;

      this.setState({
        ...data,
        displayed: false,
      });

      this.window.postMessage(
        JSON.stringify({
          hash: window.location.hash,
          editLogic: true,
          ...data,
        }),
        window.location.origin
      );
    } else {
      this.setState(
        {
          ...data,
          returnObject: get(data, "logicEditorOptions.returnObject", {}),
        },
        () => {
          if (this.state.path) {
            this.fetchAndRunTests(this.state.path);
          }
        }
      );
    }

    if (data.rule) {
      this.executeExpression({
        expression: data.rule,
        name: data.name,
      });
    } else {
      this.setState({
        allLogicNamespaces: [],
        expressionResults: null,
      });
    }
  }

  render() {
    let { detached, displayed, rule, tempSize } = this.state;

    const zStyles = {
      bottom: displayed ? 0 : -250,
      height: displayed ? this.state.size + tempSize : 0 + "px",
      zIndex: 100001,
    };

    if (detached) {
      zStyles.bottom = 0;
      zStyles.height = "100%";
      zStyles.width = "100%";
    }

    return (
      <div className={styles.dialogWrapper}>
        <div
          style={zStyles}
          className={`cps-card cps-depth-3 ${styles.dialog}`}
        >
          {displayed ? this.getLogicEditor(rule) : null}
          {!displayed && detached && (
            <div className="cp-flex-spread-center">
              <div style={{ position: "relative", width: 400 }}>
                <CpEmptyState
                  img="es_lego_head"
                  text="Everything is awesome!"
                  subtext="You can click into another field to write it's logic."
                  cta={
                    <CpButton onClick={this.alertApp} btnType="flat">
                      Go back to skymonkey
                    </CpButton>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  insertText = (text, e) => {
    if (!this.window) {
      const newText = /\w+_\d/.test(text) ? text.split("_")[0] : text;
      if (
        this.codemirror &&
        (this.codemirror.editor.state.focused || this.state.detached)
      ) {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        this.codemirror.editor.replaceSelection(`${newText}`);
      }
    } else {
      this.window.postMessage(
        JSON.stringify({ insertText: true, text: text }),
        origin
      );
    }
  };

  getLogicEditor(rule) {
    const {
      activeTab,
      currentVariable,
      detached,
      expressionResults,
      formulaError,
      logicEditorOptions,
      name,
      saveBtnDisabled,
      showInspector,
      testRuns,
      uniqueNamespaces,
      unsavedChangesDialog,
      validating,
    } = this.state;

    const showAddendumsContent =
      !isEmpty(rule) &&
      logicEditorOptions &&
      !logicEditorOptions.isBusinessRule;
    const showBusinessRuleContent =
      logicEditorOptions && logicEditorOptions.isBusinessRule;

    if (logicEditorOptions && logicEditorOptions.fetchingReferencesSubject) {
      this.fetchingReferencesSubscription =
        logicEditorOptions.fetchingReferencesSubject.subscribe(
          {
            next: (v) => {
              this.setState({ fetchingReferences: v });
            },
          },
          catchAsyncStacktrace()
        );
    }

    const maintainTextFormattingChecked =
      get(logicEditorOptions, "formatting.case") === "same";

    return (
      <div style={{ height: "100%" }}>
        {unsavedChangesDialog && (
          <UnsavedChangesDialog
            hasError={formulaError}
            saveAndClose={this.saveLogicAndClose}
            close={this.closeLogicEditor}
            resume={() => this.setState({ unsavedChangesDialog: false })}
          />
        )}
        <div onMouseDown={this.startDrag} className={styles.sizeHandle} />
        <div className="cps-card__header cps-subheader cp-pv-8 cp-flex-spread-center">
          <div>
            <div className="cp-flex-center">
              {name.split("_")[0]}
              {logicEditorOptions &&
                logicEditorOptions.onActiveReferencesClick && (
                  <CpButton
                    icon="misc-document-calculator"
                    aria-label="references"
                    onClick={() => {
                      logicEditorOptions.onActiveReferencesClick(name);
                    }}
                  />
                )}
              {this.state.fetchingReferences && <CpLoader />}
            </div>
            <div>
              {logicEditorOptions && (
                <div className="cp-flex cp-mt-8">
                  <CpCheckbox
                    onChange={
                      showBusinessRuleContent
                        ? this.handleBusinessRuleRequiredCheckChange
                        : this.handleTaxFormRuleRequiredCheckChange
                    }
                    checked={logicEditorOptions.ruleNotRequired}
                  >
                    Rule not required
                  </CpCheckbox>
                  <CpCheckbox
                    className="cp-ml-16 cp-pt-0"
                    onChange={this.handleMaintainTextFormattingCheckChange}
                    checked={maintainTextFormattingChecked}
                  >
                    Maintain text formatting
                  </CpCheckbox>
                </div>
              )}
            </div>
          </div>
          <div className="cp-flex-spread-center">
            {validating && <ValidationFeedback />}
            <div className="cp-pl-8">
              <CpButton
                btnType="primary"
                disabled={saveBtnDisabled || validating}
                onClick={this.saveLogicAndClose}
              >
                Save
              </CpButton>
            </div>
            {detached && (
              <div className="cp-pl-8">
                <CpButton onClick={this.alertApp} btnType="flat">
                  Show skymonkey
                </CpButton>
              </div>
            )}
            {!detached && !showBusinessRuleContent && (
              <CpButton
                icon="af-external-link"
                aria-label="new window icon"
                onClick={this.newWindow}
                className="cp-ml-12"
              />
            )}
            <CpButton
              icon="close-large"
              aria-label="close editor"
              onClick={this.closeLogicEditorTest}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "calc(100% - 70px)",
          }}
        >
          <div
            className={`cps-card__body ${styles.body}`}
            style={{ paddingTop: 0 }}
          >
            <div
              className="cps-secondarynav__menu__tabs cp-flex-spread"
              style={{ borderBottom: "none" }}
            >
              <CpTabs activeId={activeTab} onChange={this.changeTab}>
                <CpTabs.Button id={"tests"}>Tests</CpTabs.Button>
                <CpTabs.Button id={"logic"}>Logic</CpTabs.Button>
                {showAddendumsContent && (
                  <CpTabs.Button id={"addendums"}>Addendums</CpTabs.Button>
                )}
              </CpTabs>
              <div className="cp-flex-center">
                {!isEmpty(testRuns) && (
                  <TestsStatus
                    refreshTestRuns={this.refreshTestRuns}
                    testRuns={testRuns}
                  />
                )}
                <ExpressionStatus results={expressionResults} />
                {activeTab === "logic" && rule && (
                  <CpButton
                    icon="misc-bright-calculator"
                    onClick={this.toggleInspector}
                    aria-label="toggle inspector"
                    style={{ zIndex: "1007" }}
                    className="cp-mh-16"
                    btnType={showInspector ? "icon-active" : "icon"}
                  />
                )}
              </div>
            </div>
            {this.getTestsTabContent()}
            {this.getLogicTabContent(showBusinessRuleContent)}
            {this.getAddendumsTabContent(showAddendumsContent)}
          </div>
          <div
            className={`cps-bg-color-background ${
              showInspector
                ? styles.yourAssistantIsShowing
                : styles.coverThatAssistant
            }`}
          >
            {name && rule && (
              <Inspector
                currentVariable={currentVariable}
                detached={detached}
                height={this.state.size + this.state.tempSize}
                name={name}
                rule={rule}
                uniqueNamespaces={uniqueNamespaces}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  getTestsTabContent = () => {
    const { activeTab, path, testRuns, tests, uniqueNamespaces } = this.state;
    return (
      <div
        className={styles.testsContainer}
        style={{
          display: activeTab === "tests" ? "block" : "none",
        }}
      >
        <TestsTab
          tests={tests}
          testRuns={testRuns}
          path={path}
          fetchAndRunTests={partial(this.fetchAndRunTests, path)}
          updateTests={this.updateTests}
          updateTestRuns={this.updateTestRuns}
          namespaces={uniqueNamespaces}
        />
      </div>
    );
  };

  getLogicTabContent = (showBusinessRuleContent) => {
    const codeMirrorOptions = {
      autoCloseBrackets: {
        explode: "[]{}",
        pairs: "##()[]{}''\"\"",
        triples: "",
      },
      autoMatchParens: true,
      extraKeys: {
        "Ctrl-Space": "autocomplete",
        "Ctrl-I": autoFormat.bind(this, this.updateRule),
      },
      gutters: ["CodeMirror-lint-markers"],
      lineNumbers: true,
      lineWrapping: false,
      lint: true,
      matchBrackets: true,
      mode: "spreadsheet",
    };

    const { activeTab, logicEditorOptions, rule, uniqueNamespaces } =
      this.state;

    return (
      <div
        style={{
          display: activeTab === "logic" ? "block" : "none",
          height: "calc(100% - 42px)",
          position: "relative",
        }}
      >
        <div
          className={`${styles.pretty} ${
            showBusinessRuleContent ? styles.prettyBusinessRuleSummary : ""
          } ${
            !get(this.state, "logicEditorOptions.isBusinessRule")
              ? styles.prettyLogicAssistant
              : ""
          }`}
        >
          <CpTooltip text="Auto-format your logic<br>CTR + I">
            <CpButton
              icon="rte-right-aligned"
              onClick={() =>
                autoFormat(this.updateRule, this.codemirror.editor)
              }
              aria-label="auto-format"
            />
          </CpTooltip>
        </div>
        <div
          className={logicEditorOptions ? styles.shortLogic : styles.fullLogic}
          style={{ width: "100%" }}
        >
          <div
            className={
              showBusinessRuleContent
                ? styles.editorWrapperWithBusinessRuleSummary
                : styles.editorWrapper
            }
          >
            {!isEmpty(uniqueNamespaces) && (
              <NamespaceBank
                insertText={this.insertText}
                namespaces={uniqueNamespaces}
              />
            )}
            <div style={{ flex: 1, padding: "24px" }}>
              <CodeMirrorComponent
                ref={(ref) => (this.codemirror = ref)}
                value={rule || ""}
                onBeforeChange={(editor, data, value) => {
                  this.codemirror = editor;
                  this.updateRule(value);
                }}
                onCursorActivity={this.handleSelectionChanges}
                options={codeMirrorOptions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  handleSelectionChanges = (editor) => {
    if (this.state.showInspector) {
      let updatedValue = "";

      if (editor.somethingSelected()) {
        const word = editor.findWordAt(editor.getCursor());
        updatedValue = editor.getRange(word.anchor, word.head);
      }

      this.setState(() => ({
        currentVariable: updatedValue,
      }));
    }
  };

  getAddendumsTabContent = (showAddendumsContent) => {
    const { activeTab, logicEditorOptions, name } = this.state;

    return (
      showAddendumsContent && (
        <div
          style={{
            display: activeTab === "addendums" ? "block" : "none",
            height: "calc(100% - 42px)",
          }}
        >
          <AddendumLogic
            name={name}
            style={{ height: "100%" }}
            taxFormOptions={logicEditorOptions}
            updateReturnObject={(addendum) => {
              this.setState((prevState) => ({
                returnObject: {
                  ...prevState.returnObject,
                  addendum,
                },
              }));
            }}
          />
        </div>
      )
    );
  };

  newWindow = (_) => {
    const { origin, hash } = window.location;
    const {
      SystemJS: { sofe },
    } = window;
    const { name, path, rule, logicEditorOptions } = this.state;

    this.setState({ displayed: false });

    if (!this.window) {
      const newWindow = window.open(
        "",
        "_blank",
        "location=no,menubar=no,status=no,width=1024,height=768,centerscreen=yes,chrome=yes"
      );

      newWindow.document.open();

      newWindow.document.write(
        newWindowMarkup({
          hash,
          logicEditorOptions,
          name,
          origin,
          path,
          rule,
          sofeManifest: sofe,
        })
      );

      newWindow.document.close();

      this.window = newWindow;

      this.window.onunload = () => {
        delete this.window;
        this.setState({ detached: false, displayed: false });
      };
    }

    this.window.postMessage("logic-ui-window-setup", origin);

    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== origin) return;

        const data = JSON.parse(event.data);

        if (data.newLogicRule) {
          if (data.rule !== this.state.originalRule) {
            this.state.resolve(data.rule);
          }
        } else if (data.alertApp) {
          alert("skymonkey");
        } else if (data.closedLogic) {
          this.newWindowLogicClosed = true;
        }
      },
      false
    );
  };

  alertApp = () => {
    window.source.postMessage(
      JSON.stringify({
        alertApp: true,
      }),
      window.location.origin
    );
  };

  resize = (e) => {
    if (this.state.dragging) {
      const tempSize = this.state.anchor - e.clientY;

      this.setState(() => ({
        tempSize,
      }));
    }
  };

  startDrag = (e) => {
    this.setState({
      anchor: e.clientY,
      dragging: true,
      tempSize: 0,
    });
  };

  stopDrag = () => {
    const { dragging, size, tempSize } = this.state;
    if (dragging) {
      this.setState({
        anchor: null,
        dragging: false,
        size: size + tempSize,
        tempSize: 0,
      });
    }
  };

  changeTab = (activeTab) => {
    this.setState({ activeTab }, () => {
      this.codemirror.editor.refresh();
    });
  };

  saveLogicAndClose = () => {
    const { originalRule, returnObject, resolve, rule } = this.state;

    if (rule !== originalRule) {
      resolve(rule);
    }

    const onSaveCallback = get(this.state, "logicEditorOptions.onSaveCallback");
    if (onSaveCallback) {
      onSaveCallback({ ...returnObject, logic: rule });
    }

    this.closeLogicEditor();
  };

  closeLogicEditor = () => {
    this.setState({
      activeTab: "logic",
      displayed: false,
      expressionResults: null,
      logicEditorOptions: null,
      name: "",
      originalRule: "",
      rule: "",
      unsavedChangesDialog: false,
    });

    if (this.state.detached) {
      window.source.postMessage(
        JSON.stringify({
          closedLogic: true,
        }),
        window.location.origin
      );
    }

    this.escKeydownRef.current && this.escKeydownRef.current.remove();
    this.crtlXKeydownRef.current && this.crtlXKeydownRef.current.remove();
    this.crtlSKeydownRef.current && this.crtlSKeydownRef.current.remove();

    setBadFields([]);
  };

  closeLogicEditorTest = () => {
    if (this.state.rule !== this.state.originalRule) {
      this.setState({ unsavedChangesDialog: true });
    } else {
      this.closeLogicEditor();
    }
  };

  updateRule = (newRule) => {
    this.setState(
      {
        rule: newRule,
        saveBtnDisabled: true,
      },
      () => {
        if (this.state.path) {
          this.debouncedExecuteExpression({});
        }
      }
    );
  };

  fetchAndRunTests = (path) => {
    this.fetchTests(path);
    this.runTests(path);
  };

  fetchTests = (path) => {
    getTests(path).subscribe((tests) => {
      this.setState(
        { tests },
        this.getUniqueNamespaces(tests, this.state.allLogicNamespaces)
      );
    }, catchAsyncStacktrace());
  };

  executeExpression = ({
    expression = this.state.rule,
    taxFormId = getTaxFormId(),
    name = this.state.name,
  }) => {
    setBadFields([]);

    this.setState({
      validating: true,
    });

    _executeExpression({
      expression,
      taxFormId,
      name,
    }).subscribe((results) => {
      const formulaError = results && results.status === "error";
      const allLogicNamespaces = formulaError
        ? this.state.allLogicNamespaces
        : get(results, "variables", []);
      this.setState(
        {
          allLogicNamespaces,
          expressionResults: results,
          formulaError,
          saveBtnDisabled: formulaError,
          validating: false,
        },
        this.getUniqueNamespaces(this.state.tests, allLogicNamespaces)
      );

      if (
        results &&
        results.details &&
        results.message ===
          "Nonexistent or improperly formatted fields in rule test rules"
      ) {
        setBadFields(results.details);
        this.codemirror.editor.performLint();
      } else {
        setBadFields([]);
      }
    });
  };

  runTests = (path) => {
    _runTests(path, this.state.rule).subscribe((testRuns) => {
      this.setState({ testRuns });
    }, catchAsyncStacktrace());
  };

  updateTests = (tests) => {
    this.setState(
      { tests },
      this.getUniqueNamespaces(tests, this.state.allLogicNamespaces)
    );
  };

  updateTestRuns = (testRuns) => {
    this.setState({ testRuns });
  };

  getUniqueNamespaces = (tests, logicNamespaces) => {
    this.setState((prevState) => {
      const allTestNamespaces = tests
        ? uniq(
            tests.reduce(
              (namespaces, test) => [...namespaces, ...keys(test.inputData)],
              []
            )
          )
        : [];
      const allLogicNamespaces = logicNamespaces ? logicNamespaces : [];
      const allNamespaces = [...allLogicNamespaces, ...allTestNamespaces];
      const existingNamespaces = prevState.uniqueNamespaces.filter(
        (namespace) => includes(allNamespaces, namespace)
      );
      const newNamespaces = allNamespaces.filter(
        (namespace) => !includes(prevState.uniqueNamespaces, namespace)
      );
      return { uniqueNamespaces: [...existingNamespaces, ...newNamespaces] };
    });
  };

  refreshTestRuns = () => {
    if (this.state.path) {
      this.runTests(this.state.path);
    }
  };

  handleBusinessRuleReadyCheckChange = (ready) => {
    this.setState((prevState) => ({
      returnObject: { ...prevState.returnObject, ready },
    }));
  };

  handleBusinessRuleRequiredCheckChange = (logic_not_needed) => {
    this.setState((prevState) => ({
      returnObject: { ...prevState.returnObject, logic_not_needed },
      logicEditorOptions: {
        ...prevState.logicEditorOptions,
        ruleNotRequired: logic_not_needed,
      },
    }));
  };

  handleTaxFormRuleRequiredCheckChange = (ruleNotRequired) => {
    this.setState((prevState) => ({
      logicEditorOptions: {
        ...prevState.logicEditorOptions,
        ruleNotRequired,
      },
      returnObject: { ...prevState.returnObject, ruleNotRequired },
    }));

    this.state.logicEditorOptions.updateTaxFormCheckboxData({
      name: this.state.name,
      ruleNotRequired,
      formatting: this.state.logicEditorOptions.formatting,
    });
  };

  handleMaintainTextFormattingCheckChange = (checked) => {
    const maintainTextFormatting = checked ? "same" : null;

    this.setState((prevState) => ({
      logicEditorOptions: {
        ...prevState.logicEditorOptions,
        formatting: {
          ...prevState.logicEditorOptions.formatting,
          case: maintainTextFormatting,
        },
      },
      returnObject: {
        ...prevState.returnObject,
        formatting: {
          ...prevState.returnObject.formatting,
          case: maintainTextFormatting,
        },
      },
    }));

    this.state.logicEditorOptions.updateTaxFormCheckboxData({
      name: this.state.name,
      ruleNotRequired: this.state.logicEditorOptions.ruleNotRequired,
      formatting: {
        ...this.state.logicEditorOptions.formatting,
        case: maintainTextFormatting,
      },
    });
  };

  toggleInspector = () => {
    this.setState((prevState) => ({ showInspector: !prevState.showInspector }));
  };
}
