import { fetchAsObservable } from "fetcher!sofe";
import { map, pluck } from "rxjs/operators";

export const getRuleTestDebug = (
  clientId,
  resolutionCaseId,
  versionId,
  taxFormId,
  name,
  rule
) =>
  fetchAsObservable(
    `/wg/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${versionId}/tax-forms/${taxFormId}/rules-tests`,
    { method: "POST", body: { "rules-tests": { name, rule } } }
  ).pipe(
    pluck("rules-tests"),
    map((rules_tests) => ({
      tabular_data: {
        ...rules_tests.calculations,
        ...rules_tests.data,
        ...rules_tests.variables,
      },
      output: rules_tests.output,
      status: rules_tests.status,
      message: rules_tests.message,
    }))
  );
