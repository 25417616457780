// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-addendum-logic-style__wrapper {
  line-height: 16px;
  height: 100%;
}

.src-addendum-logic-style__container {
  padding: 24px;
  border: 1px solid #dfdfdf;
  height: 100%;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/addendum-logic.style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[":local(.wrapper) {\n  line-height: 16px;\n  height: 100%;\n}\n\n:local(.container) {\n  padding: 24px;\n  border: 1px solid #dfdfdf;\n  height: 100%;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `src-addendum-logic-style__wrapper`,
	"container": `src-addendum-logic-style__container`
};
export default ___CSS_LOADER_EXPORT___;
