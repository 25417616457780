// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-inspector-inspector-styles__keepBottomBorder {
  border-left: none;
  border-right: none;
  border-top: none;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/inspector/inspector.styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[":local(.keepBottomBorder) {\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keepBottomBorder": `src-inspector-inspector-styles__keepBottomBorder`
};
export default ___CSS_LOADER_EXPORT___;
