import CodeMirror from "codemirror";

let defaults = {
  pairs: "##()[]{}''\"\"",
  triples: "",
  explode: "[]{}",
};

let Pos = CodeMirror.Pos;

CodeMirror.defineOption("autoCloseBrackets", false, function (cm, val, old) {
  if (old && old != CodeMirror.Init) {
    cm.removeKeyMap(keyMap);
    cm.state.closeBrackets = null;
  }
  if (val) {
    cm.state.closeBrackets = val;
    cm.addKeyMap(keyMap);
  }
});

function getOption(conf, name) {
  if (name == "pairs" && typeof conf == "string") return conf;
  if (typeof conf == "object" && conf[name] != null) return conf[name];
  return defaults[name];
}

let bind = defaults.pairs + "`";
let keyMap = { Backspace: handleBackspace, Enter: handleEnter };
for (let i = 0; i < bind.length; i++)
  keyMap["'" + bind.charAt(i) + "'"] = handler(bind.charAt(i));

function handler(ch) {
  return function (cm) {
    return handleChar(cm, ch);
  };
}

function getConfig(cm) {
  let deflt = cm.state.closeBrackets;
  if (!deflt || deflt.override) return deflt;
  let mode = cm.getModeAt(cm.getCursor());
  return mode.closeBrackets || deflt;
}

function handleBackspace(cm) {
  let conf = getConfig(cm);
  if (!conf || cm.getOption("disableInput")) return CodeMirror.Pass;

  let pairs = getOption(conf, "pairs");
  let ranges = cm.listSelections();
  for (let i = 0; i < ranges.length; i++) {
    if (!ranges[i].empty()) return CodeMirror.Pass;
    let around = charsAround(cm, ranges[i].head);
    if (!around || pairs.indexOf(around) % 2 != 0) return CodeMirror.Pass;
  }
  for (let i = ranges.length - 1; i >= 0; i--) {
    let cur = ranges[i].head;
    cm.replaceRange(
      "",
      Pos(cur.line, cur.ch - 1),
      Pos(cur.line, cur.ch + 1),
      "+delete"
    );
  }
}

function handleEnter(cm) {
  let conf = getConfig(cm);
  let explode = conf && getOption(conf, "explode");
  if (!explode || cm.getOption("disableInput")) return CodeMirror.Pass;

  let ranges = cm.listSelections();
  for (let i = 0; i < ranges.length; i++) {
    if (!ranges[i].empty()) return CodeMirror.Pass;
    let around = charsAround(cm, ranges[i].head);
    if (!around || explode.indexOf(around) % 2 != 0) return CodeMirror.Pass;
  }
  cm.operation(function () {
    cm.replaceSelection("\n\n", null);
    cm.execCommand("goCharLeft");
    ranges = cm.listSelections();
    for (let i = 0; i < ranges.length; i++) {
      let line = ranges[i].head.line;
      cm.indentLine(line, null, true);
      cm.indentLine(line + 1, null, true);
    }
  });
}

function contractSelection(sel) {
  let inverted = CodeMirror.cmpPos(sel.anchor, sel.head) > 0;
  return {
    anchor: new Pos(sel.anchor.line, sel.anchor.ch + (inverted ? -1 : 1)),
    head: new Pos(sel.head.line, sel.head.ch + (inverted ? 1 : -1)),
  };
}

function handleChar(cm, ch) {
  let conf = getConfig(cm);
  if (!conf || cm.getOption("disableInput")) return CodeMirror.Pass;

  let pairs = getOption(conf, "pairs");
  let pos = pairs.indexOf(ch);
  if (pos == -1) return CodeMirror.Pass;
  let triples = getOption(conf, "triples");

  let identical = pairs.charAt(pos + 1) == ch;
  let ranges = cm.listSelections();
  let opening = pos % 2 == 0;

  let type;
  for (let i = 0; i < ranges.length; i++) {
    let range = ranges[i],
      cur = range.head,
      curType;
    let next = cm.getRange(cur, Pos(cur.line, cur.ch + 1));
    if (opening && !range.empty()) {
      curType = "surround";
    } else if ((identical || !opening) && next == ch) {
      if (identical && stringStartsAfter(cm, cur)) curType = "both";
      else if (
        triples.indexOf(ch) >= 0 &&
        cm.getRange(cur, Pos(cur.line, cur.ch + 3)) == ch + ch + ch
      )
        curType = "skipThree";
      else curType = "skip";
    } else if (
      identical &&
      cur.ch > 1 &&
      triples.indexOf(ch) >= 0 &&
      cm.getRange(Pos(cur.line, cur.ch - 2), cur) == ch + ch &&
      (cur.ch <= 2 ||
        cm.getRange(Pos(cur.line, cur.ch - 3), Pos(cur.line, cur.ch - 2)) != ch)
    ) {
      curType = "addFour";
    } else if (identical) {
      if (!CodeMirror.isWordChar(next) && enteringString(cm, cur, ch))
        curType = "both";
      else return CodeMirror.Pass;
    } else if (
      opening &&
      (cm.getLine(cur.line).length == cur.ch ||
        isClosingBracket(next, pairs) ||
        /\s/.test(next))
    ) {
      curType = "both";
    } else {
      return CodeMirror.Pass;
    }
    if (!type) type = curType;
    else if (type != curType) return CodeMirror.Pass;
  }

  let left = pos % 2 ? pairs.charAt(pos - 1) : ch;
  let right = pos % 2 ? ch : pairs.charAt(pos + 1);
  cm.operation(function () {
    if (type == "skip") {
      cm.execCommand("goCharRight");
    } else if (type == "skipThree") {
      for (let i = 0; i < 3; i++) cm.execCommand("goCharRight");
    } else if (type == "surround") {
      let sels = cm.getSelections();
      for (let i = 0; i < sels.length; i++) sels[i] = left + sels[i] + right;
      cm.replaceSelections(sels, "around");
      sels = cm.listSelections().slice();
      for (let i = 0; i < sels.length; i++)
        sels[i] = contractSelection(sels[i]);
      cm.setSelections(sels);
    } else if (type == "both") {
      cm.replaceSelection(left + right, null);
      cm.triggerElectric(left + right);
      cm.execCommand("goCharLeft");
    } else if (type == "addFour") {
      cm.replaceSelection(left + left + left + left, "before");
      cm.execCommand("goCharRight");
    }
  });
}

function isClosingBracket(ch, pairs) {
  let pos = pairs.lastIndexOf(ch);
  return pos > -1 && pos % 2 == 1;
}

function charsAround(cm, pos) {
  let str = cm.getRange(Pos(pos.line, pos.ch - 1), Pos(pos.line, pos.ch + 1));
  return str.length == 2 ? str : null;
}

// Project the token type that will exists after the given char is
// typed, and use it to determine whether it would cause the start
// of a string token.
function enteringString(cm, pos, ch) {
  let line = cm.getLine(pos.line);
  let token = cm.getTokenAt(pos);
  if (/\bstring2?\b/.test(token.type) || stringStartsAfter(cm, pos))
    return false;
  let stream = new CodeMirror.StringStream(
    line.slice(0, pos.ch) + ch + line.slice(pos.ch),
    4
  );
  stream.pos = stream.start = token.start;
  for (;;) {
    let type1 = cm.getMode().token(stream, token.state);
    if (stream.pos >= pos.ch + 1) return /\bstring2?\b/.test(type1);
    stream.start = stream.pos;
  }
}

function stringStartsAfter(cm, pos) {
  let token = cm.getTokenAt(Pos(pos.line, pos.ch + 1));
  return /\bstring/.test(token.type) && token.start == pos.ch;
}
