import { Observable } from "rxjs";

export default function (...args) {
  return Observable.create((observer) => {
    SystemJS.import("sme-ui!sofe")
      .then((smeUI) => {
        smeUI.fetcher(...args).subscribe((resp) => {
          observer.next(resp);
          observer.complete();
        }, observer.error.bind(observer));
      })
      .catch(observer.error.bind(observer));
  });
}
