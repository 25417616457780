import { capitalize } from "lodash";

function nameStartsWith(nameBeginning, name) {
  return name.toLowerCase().indexOf(nameBeginning.toLowerCase()) === 0;
}

const METHODS = [
  "AND",
  "OR",
  "NOT",
  "IF",
  "IFONLY",
  "LARGE",
  "MAX",
  "MIN",
  "ABS",
  "ROUND",
  "FLOOR",
  "CEILING",
  "SUM",
  "COMBINE",
  "SUBSET",
  "FILTER",
  "SIZE",
  "INDEX",
  "CHOOSE",
  "LOOKUP",
  "LEFT",
  "MID",
  "RIGHT",
  "DATE",
  "AMOUNT",
  "NUMBER",
  "PERCENT",
  "PHONE",
  "EIN",
  "SSN",
  "PILLBOX",
  "ZIP",
  "AGE",
  "DAY",
  "MONTH",
  "YEAR",
  "DATEDIF",
];

export function getMethods(meth) {
  let methods = METHODS.sort();

  return new Promise((resolve, reject) => {
    if (!meth) {
      return resolve(methods);
    }

    resolve(methods.filter(nameStartsWith.bind(null, meth)));
  });
}

export function getMethodRegex() {
  const methods = [
    ...METHODS,
    ...METHODS.map((m) => capitalize(m)),
    ...METHODS.map((m) => m.toLowerCase()),
  ];

  return new RegExp(`^(${methods.join("|")})`);
}

export function getBadFieldPositions(text, badFields, CodeMirror) {
  return text.split("\n").reduce((warnings, lineText, lineIndex) => {
    return [
      ...warnings,
      ...badFields.reduce((all, field) => {
        return [
          ...getStringIndexes(lineText, field).reduce((all, index) => {
            return [
              ...all,
              {
                message: "Non-existent or improperly formatted field",
                from: CodeMirror.Pos(lineIndex, index),
                to: CodeMirror.Pos(lineIndex, index + field.length),
              },
            ];
          }, all),
        ];
      }, []),
    ];
  }, []);
}

function getStringIndexes(str, toMatch) {
  let toMatchLength = toMatch.length,
    indexMatches = [],
    match,
    i = 0;

  while ((match = str.indexOf(toMatch, i)) > -1) {
    indexMatches.push(match);
    i = match + toMatchLength;
  }

  return indexMatches;
}
