import fetcher from "src/common/fetcher.helper.js";
import { map } from "rxjs/operators";

export function getQuestionsByString(questionText, options = {}) {
  return fetcher(`questions?questionText=${questionText}`, {
    previousVersion: !!options.old,
  }).pipe(map((resp) => resp.questions));
}

export function getSharedValueIndex(options = {}) {
  return fetcher(`shared-values`, {
    previousVersion: !!options.old,
  }).pipe(map((resp) => resp["shared-values"]));
}
