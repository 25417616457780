import React from "react";
import { CprButton } from "canopy-styleguide!sofe";

export default class UnsavedChangesDialog extends React.Component {
  render() {
    const { hasError, saveAndClose, close, resume } = this.props;

    return (
      <div className="cps-modal">
        <div className="cps-modal__screen" />
        <div className="cps-modal__dialog cps-card__height-3">
          <div className="cps-card__header cps-subheader-sm">
            <span>Unsaved changes made</span>
            <a
              onClick={resume}
              className="cps-modal__dialog__close cps-icon cps-icon-close"
            />
          </div>
          {hasError && (
            <div className="cps-card__banner +large +bg-warning">
              <i className="cps-icon cps-icon-error cps-warning">
                <span>Formula error!</span>
              </i>
            </div>
          )}
          <div className="cps-card__body cps-wt-bold">
            You have changes that have not been saved yet!
          </div>
          <div className="cps-modal__dialog__actions">
            {!hasError && (
              <CprButton onClick={saveAndClose} actionType="primary">
                Save changes
              </CprButton>
            )}
            {hasError && (
              <CprButton onClick={resume} actionType="primary">
                Fix error
              </CprButton>
            )}
            <CprButton onClick={close} actionType="flat">
              Don't save
            </CprButton>
          </div>
        </div>
      </div>
    );
  }
}
