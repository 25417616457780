import { fetchAsObservable } from "fetcher!sofe";
import { fromEvent } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  pluck,
} from "rxjs/operators";

export function searchTasks(inputElement) {
  let input = fromEvent(inputElement, "input");
  let keydown = fromEvent(inputElement, "keydown");

  const values = input.pipe(map((e) => e.target.value.toLowerCase()));

  return {
    arrows: keydown.pipe(
      map((e) => e.keyCode),
      filter((code) => code === 38 || code === 40 || code === 13 || code === 27)
    ),
    empty: values.pipe(
      filter((text) => text.trim().length < 1),
      debounceTime(500)
    ),
    results: values.pipe(
      distinctUntilChanged(),
      filter((text) => text.trim().length > 1),
      debounceTime(500),
      mergeMap(executeSearch)
    ),
  };
}

export function executeSearch(name_filter) {
  return fetchAsObservable(
    `/api/tasks?service_type=tax_return&limit=10&sort=name${
      name_filter ? `&name_filter=${encodeURIComponent(name_filter)}` : ""
    }`
  ).pipe(pluck("tasks"));
}
