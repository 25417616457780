import React from "react";
import ReactDOM from "react-dom";
import LogicEditor from "src/logic-editor.component.js";

export default openLogicEditor;
export { openLogicEditor, showDetachedLogicEditor, isActive, insertText };

const div = document.createElement("div");
document.body.appendChild(div);

let instance;

instance = ReactDOM.render(<LogicEditor />, div);

function openLogicEditor(name, path, rule = "", logicEditorOptions) {
  if (!name) throw new Error("A name is required for opening the logic editor");
  if (!path) throw new Error("A path is required for opening the logic editor");
  if (!instance) throw new Error("Error initializing the logic editor");

  return new Promise((resolve, reject) => {
    instance.initialize({
      logicEditorOptions,
      displayed: true,
      name,
      resolve,
      reject,
      path,
      rule,
      originalRule: rule,
    });
  });
}

function showDetachedLogicEditor() {
  instance.setState({
    displayed: true,
    detached: true,
  });
}

function isActive() {
  return (
    instance &&
    (instance.state.displayed ||
      (instance.window && !instance.newWindowLogicClosed)) // new window is displayed and it currently is NOT editing a rule
  );
}

function closeLogicEditor() {
  instance.setState({
    displayed: false,
  });
}

function insertText(...args) {
  if (!instance) throw new Error("Error initializing the logic editor");

  instance.insertText(...args);
}
